.MuiOutlinedInput-root {
  display: flex;
}

.MuiSelect-select.MuiSelect-select {
  padding-right: 14px !important;
}

.MuiButton-containedSecondary {
  width: 45%;
}

.MuiMobileStepper-dots {
  padding: 0% 40%;
  margin: -60px 0px 0px 0px;
}

.MuiMobileStepper-dotActive {
  background: #32c6a9 !important;
}

.MuiSelect-icon {
  margin-left: 92%;
}

.MuiStepConnector-line {
  margin: 0px 10px;
}

.MuiInputLabel-asterisk {
  display: none;
}

.MuiFormControlLabel-root {
  height: 35px;
}

.MuiCheckbox-root {
  color: #707070 !important;
}
.MuiStepLabel-alternativeLabel {
  font-size: 14px !important;
}
.MuiStepLabel-active {
  color: #32c6a9 !important;
  font-size: 14px !important;
}

.MuiStepLabel-completed {
  color: #32c6a9 !important;
  font-size: 14px !important;
}

.MuiStepper-root {
  padding: 24px;
}

.MuiTypography-body1 {
  font-size: 16px !important;
}

.MuiFormGroup-root {
  margin-right: -24px;
}

.text_mentor {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6%;
  text-align:center;
  font-weight: bold;
  direction: rtl;
}

@media only screen and (max-width: 768px) {
  .MuiStepLabel-alternativeLabel {
    font-size: 10px !important;
    margin-top: 5px !important;
  }
  .MuiStepLabel-active {
    font-size: 10px !important;
  }

  .MuiStepLabel-completed {
    font-size: 10px !important;
  }

  .MuiStepper-root {
    padding: 8px !important;
  }

  .MuiTypography-body1 {
    font-size: 14px !important;
  }

  .main-content {
    margin-bottom: 16px;
  }
  

  .text_mentor {
    display: inline-block; 
  }
  .first {
    display: inline-block;
  }
  .second {
    display: inline-block;
  }
  .third {
    display: inline-block;
  }
}

@media only screen and (max-width: 1439px) {
  .MuiGrid-grid-xs-8 {
    flex-grow: 0 !important;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }

  .MuiGrid-grid-xs-2 {
    flex-grow: 0px !important;
    max-width: 0px !important;
    flex-basis: 0px !important;
  }

  .MuiStepper-root {
    margin-left: 0;
  }
}

@media only screen and (min-width: 560px) {
  .MuiButton-containedSecondary {
    width: 174px;
  }
}
