/* component styles */

.main {
  padding: 24px;
  padding-bottom: 12px;
}

.main-content {
  overflow: auto !important;

  position: relative;
  border: 1px solid #707070;
  margin-bottom: 34px;
}

.main-content-success {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: 24px;
}

.wrapper {
  height: 510px !important;
  overflow: auto !important;
  position: relative;
  padding: 32px 32px 0px 32px;
  padding-bottom: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  box-shadow: 0px 5px 6px #4a454729;
}

.white-space-wrapper-bottom {
  position: absolute;
  bottom: 0;
  background-color: #fff;
  height: 16px;
  width: 100%;
}

.wrapper-third-page {
  padding: 26px;
  padding-bottom: 15px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}

.coupon-wrapper {
  margin-bottom: 24px;
}

.image-last-page {
  position: absolute;
  top: 45px;
  left: 16px;
  z-index: 99;
}

.image-success {
  width: 100px;
  height: 100px;
  text-align: center;
  margin: 16px auto;
}

.title {
  text-align: right;
  font-family: Regular 32px/42px Assistant;
  letter-spacing: 0;
  color: #4a4547;
  opacity: 1;
  margin-right: 40px;
}
.title-heb {
  text-align: left;
  font-family: Regular 32px/42px Assistant;
  letter-spacing: 0;
  color: #4a4547;
  opacity: 1;
  margin-left: 40px;
}

.text-success {
  margin: 44px 0;
  color: #4a4547;
}

.text-success-center {
  text-align: center;
  color: #4a4547;
}

.text {
  width: 480px;
  text-align: right;
  font-size: 20px;
  font-family: Regular 32px/42px Assistant;
  letter-spacing: 0;
  color: #4a4547;
  opacity: 1;
  margin-right: 40px;
}
.text-heb {
  width: 480px;
  text-align: left;
  font-size: 20px;
  font-family: Regular 32px/42px Assistant;
  letter-spacing: 0;
  color: #4a4547;
  opacity: 1;
  margin-left: 40px;
}

.form-values {
  color: #4a4547;
}
.image-mobile {
  width: 100px;
  height: 40px;
  margin: 0 auto;
  position: relative;
  top: -10px;
  left: -10px;
}

.button-container {
  display: flex;
  justify-content: space-between;
}

.button-content {
  padding: 2px 5x 2px 5px;
}

.button-back {
  width: 45%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: "#FFFFFF 0% 0% no-repeat padding-box";
  opacity: 1;
  border-radius: 4px;
  color: #32c6a9;
  box-shadow: none;
  font-size: 21px;
  font-weight: bold;
  cursor: pointer;
}

.button-back:hover {
  box-shadow: 0px 3px 6px #32c6a9b3;
}

.button-cancel {
  width: 100px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: "#FFFFFF 0% 0% no-repeat padding-box";
  opacity: 1;
  border-radius: 4px;
  color: #32c6a9;
  box-shadow: none;
  font-size: 21px;
  font-weight: bold;
  cursor: pointer;
  margin-left: 13%;
}

.pageImg {
  position: relative;
  top: 45px;
  left: 23px;
}
.pageImg-heb {
  position: relative;
  top: 45px;
  right: 23px;
}

.iframe-wrapper {
  height: 500px;
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch !important;
  position: relative;
}

iframe {
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 1px;
  min-width: 100%;
  width: 315px;

  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch !important;
}

.loader-iframe {
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 124px auto;

  position: relative;
  z-index: 999;
}

.error-payment {
  text-align: right;
  color: #4a4547;
  opacity: 1;
  font-size: 20px;
  line-height: 1.7rem;
  margin-bottom: 16px;
  letter-spacing: 0;
}

/* The Modal (background) */
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

/* Modal Content/Box */
.modal-content {
  text-align: center;
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.modal-close {
  width: 50%;
  opacity: 1;
  border-radius: 4px;
  color: #32c6a9;
  box-shadow: none;
  font-size: 21px;
  font-weight: bold;
  cursor: pointer;
  border: 2px solid #32c6a9;
}

.share-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
}

.share-twitter {
  width: 174px;
  height: 48px;
  background: #29a9e1 0% 0% no-repeat padding-box;
  color: white;
  font-size: 20px;
  box-shadow: none;
  font-weight: 500;
  border-radius: 4px;
  opacity: 1;
  margin-right: 30px;
}

.share-facebook {
  width: 174px;
  height: 48px;
  background: #3e5c9a 0% 0% no-repeat padding-box;
  color: white;
  font-size: 20px;
  box-shadow: none;
  font-weight: 500;
  border-radius: 4px;
  opacity: 1;
  margin-right: 30px;
}

.share-whatsapp {
  width: 174px;
  height: 48px;
  background: #57f676 0% 0% no-repeat padding-box;
  color: white;
  font-size: 18px;
  box-shadow: none;
  font-weight: 500;
  border-radius: 4px;
  opacity: 1;
  margin-right: 30px;
}

.error-text {
  height: 10px;
  font-size: 12px;
  color: red;
  margin-top: -20px;
}
.logo-text {
  font-size: 10px;
  color: #4a4547;
  direction: ltr;
  margin: 0px auto;
}
.logo-text-heb {
  font-size: 10px;
  color: #4a4547;
  direction: rtl;
  margin: 0px auto;
}

.explanation-classes {
  font-size: 14px;
  color: #4a4547;
  margin-top: -31px;
  margin-bottom: 18px;
  margin-left: 14px;
}

.remove-button-text {
  position: absolute;
  bottom: -37px;
  left: 8px;
  font-size: 14px;
  color: #4a4547;
}

.classSubclassRow {
  /* display: flex; */
  margin: 28px auto;
  position: relative;
  z-index: 99;
}

.remove-icon {
  cursor: pointer;
  color: #a7a7a7 !important;
}

.remove-icon:hover {
  color: #eb6464 !important;
}

.checkbox-text {
  margin: 0 24px 0 0;
  color: #1565c0;
  font-size: 12px;
  font-weight: 600;
  vertical-align: middle;
  position: relative;
  top: -2px;
}
.checkbox-text-heb {
  margin: 0 0 0 0;
  color: #1565c0;
  font-size: 12px;
  font-weight: 600;
  vertical-align: middle;
  position: relative;
  top: -2px;
}

.info-icon {
  position: absolute;
  top: -2px;
}
.checkbox-wrapper > MuiFormControlLabel-root {
  margin-right: -11px !important;
}

.contact-info-div {
  direction: ltr;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.contact-info-div-heb {
  direction: rtl;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.contact-text-bottom {
  /* margin-right: 24px; */
  width: 60%;
  color: #4a4547;
}

a.terms {
  width: 40%;
}

@media only screen and (max-width: 768px) {
  .contact-info-div {
    font-size: 11px;
  }

  .button-back {
    font-size: 14px;
  }

  .main-content {
    margin-bottom: 16px;
  }
}

/* Responsive for web */

@media only screen and (min-width: 1440px) {
  .main {
    padding: 0;
  }

  .page-form {
    margin: 0px 170px;
  }

  .image-mobile {
    display: none;
  }

  .button-container {
    height: 84px;
  }

  .button-back {
    border: 2px solid #32c6a9;
  }
}

@media only screen and (max-width: 1439px) {
  .pageImg {
    display: none;
  }

  .button-cancel {
    display: none;
  }
}

@media only screen and (min-width: 560px) {
  .button-back {
    width: 174px;
  }

  .modal-close {
    width: 174px;
  }

  .modal-content {
    width: 350px;
  }
}

@media only screen and (min-width: 1200px) {
  .wrapper-success {
    padding: 20px 170px;
  }
}

@media only screen and (max-width: 560px) {
  .share-buttons {
    display: inline-block;
  }

  .share-btns {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 1000px) {
  iframe {
    height: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .wrapper {
    max-height: 371px;
    overflow: auto !important;
    padding: 16px;
  }
}
